
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { Action } from "vuex-module-decorators";

export default defineComponent({
  name: "profile",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const { t } = useI18n();
    const store = useStore();

    const validationSchema = Yup.object().shape({
      profile: Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Please enter a valid Email")
          .nullable()
          .label("Email"),
      }),
    });

    const profile = {
      id: null,
      image_url: "media/avatars/blank.jpg",
      image: null,
      name: null,
      nric: null,
      email: null,
      phone: null,
      gender: null,
      birthdate: null,
    };

    const raw_id = null;

    const genders = [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ];

    return {
      i18n: useI18n(),
      store,
      raw_id,
      profile,
      genders,
      validationSchema,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        const url = process.env.VUE_APP_API_URL; // ✅
        const data = this.store.getters.getProfile;

        if (data.user_raws.length > 0) {
          for (let index of Object.keys(data.user_raws)) {
            if (data.user_raws[index].type == "profile") {
              this.profile = {
                id: data.user_raws[index].user_id,
                image_url: data.image_url,
                image: null,
                name: data.user_raws[index].raw.name,
                nric: data.user_raws[index].raw.nric,
                email: data.user_raws[index].raw.email,
                phone: data.user_raws[index].raw.phone,
                gender: data.user_raws[index].raw.gender,
                birthdate: data.user_raws[index].raw.birthdate,
              };
              this.raw_id = data.user_raws[index].id;
            }
          }
        } else {
          this.profile = {
            id: data.id,
            image_url: "media/avatars/blank.jpg",
            image: null,
            name: data.name,
            nric: data.nric,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            birthdate: data.birthdate,
          };
        }
      });
    },
    imageOnChange(event) {
      this.profile.image_url = URL.createObjectURL(event.target.files[0]);
    },
    onSubmit(values) {
      this.profile.image = values.profile.image;

      const data = {
        id: this.raw_id,
        user_id: this.profile.id,
        type: "profile",
        raw: this.profile,
        image: values.profile.image,
      };

      const formData = new FormData();

      for (const key in data) {
        if (data[key] === null || data[key] === undefined) continue;

        if (typeof data[key] === "object") {
          if (Array.isArray(data[key])) {
            for (const array in data[key]) {
              if (data[key][array] instanceof File) {
                formData.append(key, data[key][0]);
                continue;
              }

              for (const object in data[key][array]) {
                if (
                  data[key][array][object] != null ||
                  data[key][array][object] != ""
                ) {
                  formData.append(
                    `${key}[${array}][${object}]`,
                    data[key][array][object]
                  );
                }
              }
            }
          } else {
            for (const objKey in data[key]) {
              formData.append(`${key}[${objKey}]`, data[key][objKey]);
            }
          }
        } else {
          formData.append(key, data[key]);
        }
      }

      if (this.raw_id) {
        formData.append("_method", "PATCH");
        this.store.dispatch(Actions.UPDATE_PROFILE, formData).then(() => {
          Swal.fire({
            text: "Your profile has been updated",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            this.$router.go(0);
          });
        });
      } else {
        this.store.dispatch(Actions.STORE_PROFILE, formData).then(() => {
          Swal.fire({
            text: "Your profile has been updated",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            this.$router.go(0);
          });
        });
      }
    },
  },

  async mounted() {
    this.init();

    setCurrentPageBreadcrumbs("Profile", []);
  },
});
